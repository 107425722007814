import Messages from "./chat/Messages";
import MessageInput from "./chat/MessagesInput";
import React from "react";

const ChatLobby = ({ socket, chatName, userName, userImage, signedIn, userID, lobbyUsers, lobby }) => {
    return (
        <>
            {chatName ? <h2>{chatName}</h2> : <h2>Community Chat</h2>}
            <div className="card">
                <div className="card-body chatBody">
                    <div className="container">
                        <div className="row">
                            <div className="col-8">
                                { socket && userName ? (<Messages lobbyName={lobby} socket={socket} signedIn={signedIn} userID={userID} userName={userName} userImage={userImage} />) : (<>Not Connected</>)}
                            </div>
                            <div className="col-4">
                                {lobbyUsers && lobbyUsers
                                    .sort((a, b) => a.name - b.name)
                                    .map( (user, index) => {
                                        if (user.lobby === lobby) {
                                            return (<span key={index}>
                                                {user.image && <span className="user">
                                                    <img src={user.image} alt={user.name} className="lobbyListImage" />
                                                </span>}
                                                {user.name}<br/>
                                            </span>);
                                        }
                                        return null;
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    { socket ? (<MessageInput socket={socket} userID={userID} userName={userName} userImage={userImage} />) : (<>Not Connected</>)}
                </div>
            </div>
            <small className="text-secondary">(chat history disappears after 30 min)</small>
        </>
    );
}
export default ChatLobby;