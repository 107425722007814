import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const CurrentGameSessions = ({ socket, gameName }) => {
    const [sessions, setSessions] = useState([]);

    useEffect( () => {
        const sessionListener = (sessions) => {
            console.log("got sessions", sessions);
            setSessions(sessions);
        };

        console.log("gameName", gameName);
        if (gameName && socket) {
            socket.on('game:' + gameName + ':returnSessions', sessionListener);
            socket.emit('game:' + gameName + ':getSessions');
        }
    }, [socket, gameName]);

    if (gameName === '') return (<></>);
    return (<>
        <h2>Games in Session</h2>
        <div className="card">
            <div className="card-body p-1 small">
                {sessions && sessions.map( session => {
                    return (<div className="container my-1 mx-0 small">
                        <div className="row">
                            <div className="col">
                                {session.owner}
                            </div>
                            <div className="col">
                                {session.status}
                            </div>
                            <div className="col">
                                {session.players}
                            </div>
                            <div className="col text-end">
                                <Link className="btn btn-sm btn-success" to={`/${gameName}/${session.sessionUUID}`}>Join</Link>
                            </div>
                        </div>
                    </div>);
                })}
                {sessions.length=== 0 && <div className="container my-1 mx-0 small">
                    <div className="row">
                        <div className="col-12">
                            No current games in progress
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    </>);
}

export default CurrentGameSessions;