import Messages from "./chat/Messages";
import MessageInput from "./chat/MessagesInput";

const Chat = ({ socket, chatName, userName, userImage, signedIn, userID, userEmail, lobbyName }) => {
    return (
        <>
            {chatName ? <h2>{chatName}</h2> : <h2>Community Chat</h2>}
            <div className="card">
                <div className="card-body chatBody">
                    { socket && userName ? (<Messages lobbyName={lobbyName} socket={socket} signedIn={signedIn} userID={userID} userName={userName} userEmail={userEmail} userImage={userImage} />) : (<>Not Connected</>)}
                </div>
                <div className="card-footer">
                    { socket ? (<MessageInput socket={socket} userID={userID} userName={userName} userEmail={userEmail} userImage={userImage} />) : (<>Not Connected</>)}
                </div>
            </div>
            <small className="text-secondary">(chat history disappears after 30 min)</small>
        </>
    );
}
export default Chat;