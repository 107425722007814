import React, {useEffect, useRef, useState} from 'react';

function Messages({ socket, signedIn, lobbyName }) {
    const [messages, setMessages] = useState({});
    const messagesRef = useRef(null);

    const scrollToBottom = () => {
        messagesRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    useEffect(() => {
        const messageListener = (message) => {
            setMessages((prevMessages) => {
                const newMessages = {...prevMessages};
                newMessages[message.id] = message;
                return newMessages;
            });
            scrollToBottom();
        };
        const deleteMessageListener = (messageID) => {
            setMessages((prevMessages) => {
                const newMessages = {...prevMessages};
                delete newMessages[messageID];
                return newMessages;
            });
        };
        const reFreshMessagesListener = (status) => {
            if (status === true) {
                setMessages({});
                socket.emit('getMessages');
            }
        };
        socket.on('refreshMessages', reFreshMessagesListener);
        socket.on('message', messageListener);
        socket.on('deleteMessage', deleteMessageListener);
        if (signedIn) {
            socket.emit('getMessages');
        }
        return () => {
            socket.off('message', messageListener);
            socket.off('deleteMessage', deleteMessageListener);
        };
    }, [socket, signedIn]);

    return (
        <div className="message-list">
            {[...Object.values(messages)]
                .filter((message) => message.lobby ===  lobbyName)
                .sort((a, b) => a.time - b.time)
                .map((message) => {
                    if (message.user.name === 'system') {
                        return null; // hide system messages
                        return (
                            <div
                                key={message.id}
                                className="systemMessage"
                                title={`Sent at ${new Date(message.time).toLocaleTimeString()}`}
                            >
                                <span className="message">{message.value}</span>
                            </div>
                        );
                    }
                    return (
                        <div
                            key={message.id}
                            className="message-container"
                            title={`Sent at ${new Date(message.time).toLocaleTimeString()}`}
                        >
                            {message.user.image && <div className="chatUserImage">
                                <img src={message.user.image} alt={message.user.name} className="chatImage" />
                            </div>}
                            <div className="date">
                                {new Date(message.time).toLocaleTimeString()} - {message.user.name} said
                            </div>
                            <div className="message">{message.value}</div>
                        </div>
                    )
                })
            }
            <div className="mt-4" ref={messagesRef} />
            <hr />
        </div>
    );
}

export default Messages;