import React, { useState } from 'react';

const MessageInput = ({socket, userID, userName}) => {
    const [value, setValue] = useState('');
    const submitForm = (e) => {
        e.preventDefault();
        if (value !== '') socket.emit('message', {userID: userID, userMessage: value});
        setValue('');
    };

    return (
        <form onSubmit={submitForm}>
            {userName && <input
                autoFocus
                value={value}
                placeholder="Type your message"
                onChange={(e) => {
                    setValue(e.currentTarget.value);
                }}
            />}
            {userName === '' && <>Log in to chat</>}
        </form>
    );
};

export default MessageInput;