import React, {useEffect, useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import Chat from "../../components/Chat";
import PlayersInLobby from "../../components/PlayersInLobby";
import {Link} from "react-router-dom";
import CurrentGameSessions from "../../components/CurrentGameSessions";

let lobbyName = "farkle";
const FarkleLobby = ({socket, userName, lobbyUsers, userImage, userID, userEmail, signedIn}) => {
    const [newGameUUID, setNewGameUUID] = useState('');

    useEffect( () => {
        if (socket && userID && signedIn) {
            socket.emit('changeLobby', {lobbyName: lobbyName, userID});
        }
    }, [socket, userID, signedIn]);

    useEffect( () => {
        if (newGameUUID === '') {
            createNewGame();
        }
    }, [newGameUUID]);

    function createNewGame() {
        setNewGameUUID(uuidv4());
    }

    return (<>
        <div className="container-fluid">
            <div className="container">
                <div className="row pb-4">
                    <div className="col-6 py-4">
                        <Link className="btn btn-secondary" aria-current="page" to="/">Back To Lobby</Link>
                    </div>
                    <div className="col-6 py-4 text-end">
                        <Link className="btn btn-success" to={`/farkle/${newGameUUID}`}>Start New Game</Link>
                    </div>
                    <div className="col-12">
                        <h1>Farkle (2-10 Players)</h1>
                    </div>
                    <div className="col-12 col-sm-4">
                        <CurrentGameSessions gameName="farkle" socket={socket} />
                    </div>
                    <div className="col-12 col-sm-4">
                        <Chat chatName="Farkle Chat" lobbyName="farkle" socket={socket} userID={userID} signedIn={signedIn} userName={userName} userEmail={userEmail} userImage={userImage} />
                    </div>
                    <div className="col-12 col-sm-4">
                        <PlayersInLobby lobbyUsers={lobbyUsers} lobby={lobbyName} />
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default FarkleLobby;