import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import { Route, Routes } from "react-router-dom";
import userActions from "./client/userActions";

// LAYOUT COMPONENTS
import Header from "./layout/Header";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";

// PAGES
import Lobby from "./pages/Lobby";

import './App.css';
import Content from "./pages/Content";
import FarkleLobby from "./games/farkle/FarkleLobby";
import FarkleGame from "./games/farkle/FarkleGame";


function App() {
    const [socket, setSocket] = useState(null);
    const [userID, setUserID] = useState('');
    const [userShortName, setUserShortName] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userImage, setUserImage] = useState('');
    const [userLobby, setUserLobby] = useState('');
    const [lobbyUsers, setLobbyUsers] = useState([]);
    const [gameUsers, setGameUsers] = useState([]);
    const [signedIn, setSignedIn] = useState(false);


    function updateLobbyListener(lobbyResults) {
        setLobbyUsers(lobbyResults);
    }
    function updateGameListener(gameResults) {
        console.log("updateGameListener", gameResults)
        setGameUsers(gameResults);
    }
    function signedInListener(signedInResults) {
        setSignedIn(signedInResults);
    }

    useEffect(() => {
        const newSocket = socketIOClient(`https://www.bombbombfridays.com`); // , { transports: ["websocket"]}

        setUserLobby('lobby');
        newSocket.on('connect', () => {
            console.log(`Client connected: ${newSocket.id}`)
        });
        newSocket.on('disconnect', (reason) => {
            console.log(`Client disconnected: ${reason}`)
        });
        newSocket.on('connect_error', (reason) => {
            // console.log(`Client connect_error: ${reason}`)
        });
        newSocket.on('signedIn', signedInListener);
        newSocket.on('lobbyResults', updateLobbyListener);
        newSocket.on('gameResults', updateGameListener);
        setSocket(newSocket);
    }, [setSocket]);

    function onSignIn(googleUser) {
        let profile = googleUser.getBasicProfile();

        let uID = profile.getId();
        let uName = profile.getName();
        let uEmail = profile.getEmail();
        let uShortName = profile.getGivenName();
        let uImage = profile.getImageUrl();

        setUserID(uID);
        setUserName(uName);
        setUserEmail(uEmail);
        setUserShortName(uShortName);
        setUserImage(uImage);

        // sending signin to socket

        console.log("Signing In");
        socket.emit('signIn', {userInfo: {userID: uID, name: uName, fullName: uShortName, image: uImage, email: uEmail}});
    }

    function handleLogout() {
        setSignedIn(false);
        socket.emit('signOut');
        setUserEmail('');
        setUserName('');
        setUserShortName('');
        setUserImage('');
        setUserID('');
    }

    return (
        <>
            <header>
                {socket && <Navbar onSignIn={onSignIn} handleLogout={handleLogout} userID={userID} userName={userName} userEmail={userEmail} userImage={userImage} />}
            </header>
            <Routes>
                <Route
                    exact path="/"
                    element={<Lobby
                        socket={socket}
                        userID={userID}
                        signedIn={signedIn}
                        userName={userName}
                        userEmail={userEmail}
                        userImage={userImage}
                        userLobby={userLobby}
                        lobbyUsers={lobbyUsers}
                        gameUsers={gameUsers}
                    />}
                />

                <Route
                    exact path="/farkle"
                    element={<FarkleLobby
                        socket={socket}
                        userID={userID}
                        signedIn={signedIn}
                        userShortName={userShortName}
                        userName={userName}
                        userEmail={userEmail}
                        userImage={userImage}
                        userLobby={userLobby}
                        lobbyUsers={lobbyUsers}
                        gameUsers={gameUsers}
                    />}
                />

                <Route
                    exact path="/farkle/:gameSession"
                    element={<FarkleGame
                        socket={socket}
                        userID={userID}
                        signedIn={signedIn}
                        userShortName={userShortName}
                        userName={userName}
                        userEmail={userEmail}
                        userImage={userImage}
                        userLobby={userLobby}
                        lobbyUsers={lobbyUsers}
                        gameUsers={gameUsers}
                    />}
                />


                <Route exact path="/privacy-policy" element={<Content contentType="privacy" />} />
                <Route exact path="/tos" element={<Content contentType="tos" />} />
            </Routes>
            <footer>
                <Footer />
            </footer>
        </>
    );
}

export default App;
