import React, {useState, useRef, useEffect, createRef, useReducer} from "react";
import ReactDice from 'react-dice-complete';
import 'react-dice-complete/dist/react-dice-complete.css'
import * as PropTypes from "prop-types";
import {Link, useParams} from "react-router-dom";
import ChatLobby from "../../components/ChatLobby";

let faceColor = '#dfdfdf';
let dotColor = '#000000';
let outline = true;
let outlineColor = '#cfcfcf';
let dotSize = 20;
let dieSize = 80;
let rollSeconds = 0.8;

const FarkleGame = ({ socket, userName, userImage, signedIn, userID, lobbyUsers }) => {
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const [gameSessionData, setGameSessionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [gameSession, setGameSession] = useState(null);
    const [sessionName, setSessionName] = useState('');
    const [gameStatus, setGameStatus] = useState('');
    const [gameAdmin, setGameAdmin] = useState('');
    const [totalPlayers, setTotalPlayers] = useState(0);
    const [currentGame, setCurrentGame] = useState(null);
    const [diceHolder, setDiceHolder] = useState([0,0,0,0,0,0]);
    const [updateHolds, setUpdateHolds] = useState(false);

    const [diceNum, setDiceNum] = useState([]);
    const [heldDice, setHeldDice] = useState([]);
    const [currentTurnDice, setCurrentTurnDice] = useState([]);
    const [processedTurns, setProcessedTurns] = useState([]);
    const [currentTurn, setCurrentTurn] = useState(0);
    const [turns, setTurns] = useState([]);
    const [displayTurns, setDisplayTurns] = useState([]);
    const [numDice, serNumDice] = useState(6);
    const [gameText, setGameText] = useState({points: 0, pointsText: ''});

    const diceRoller = useRef();
/*
// server listeners
mySocket.on('game:farkle:getSessions', () => this.gameSessionHandler('farkle', 'list'));
mySocket.on('game:farkle:joinSession', (data) => this.gameSessionHandler('farkle', 'join', data));
mySocket.on('game:farkle:sessionExists', (data) => this.gameSessionHandler('farkle', 'exists', data));
mySocket.on('game:farkle:startSession', (data) => this.gameSessionHandler('farkle', 'start', data));
mySocket.on('game:farkle:endSession', (data) => this.gameSessionHandler('farkle', 'end', data));

// server sends
this.allSockets.to('farkle').emit('game:farkle:returnSessions', sessions);
this.allSockets.to('farkle').emit('game:farkle:returnSessionExists', sessionExists);

*/
    // SET GAME SESSION AND TELL SOCKET YOU WANT TO JOIN SESSION
    const Params = useParams();

    useEffect( () => {
        const sessionHandler = (data) => {
            if (data === false) {
                let sessionData = {
                    sessionUUID: Params.gameSession,
                    fileName: Params.gameSession + ".json",
                    sessionName: "Farkle",
                    owner: userName,
                    status: 'pending',
                    game: 'farkle',
                    players: 1,
                    topScore: 0
                }
                setGameSessionData(sessionData);
                socket.emit("game:farkle:startSession", sessionData);
            } else {
                socket.emit("game:farkle:joinSession", {gameSession: Params.gameSession, userId: userID});
            }
            setGameSession(Params.gameSession);
        };
        const updateSessionHandler = (data) => {
            console.log("updateSessionHandler", data);
            setSessionName(data.sessionName);
            setTotalPlayers(data.players);
            setGameAdmin(data.owner);
            setGameStatus(data.status);
            setLoading(false)
        }

        if (signedIn === true) {
            console.log("FarkleGame", Params, socket, signedIn);

            // handle if session exists
            socket.on('game:farkle:returnSessionExists', sessionHandler);
            socket.on('game:farkle:returnUpdateSession', updateSessionHandler);

            if (socket !== null && Params.gameSession !== gameSession && Params.gameSession) {
                // check if game session exists
                socket.emit('game:farkle:sessionExists', Params.gameSession);
            }
        }
    }, [Params, socket, signedIn]);
    useEffect( () => {
        if (currentTurnDice.length > 0 && currentTurnDice !== processedTurns) {
            setProcessedTurns(currentTurnDice);
            calculateRollDiceScore();
        }
    }, [currentTurnDice]);

    const rollTheDice = () => {
        let diceNum = [];
        diceNum[0] = generateRandomNumber();
        diceNum[1] = generateRandomNumber();
        diceNum[2] = generateRandomNumber();
        diceNum[3] = generateRandomNumber();
        diceNum[4] = generateRandomNumber();
        diceNum[5] = generateRandomNumber();
        setDiceNum(diceNum);
        diceRoller.current?.rollAll(diceNum);
    }
    const generateRandomNumber = () => {
        return Math.floor((Math.random() * 6) + 1);
    }

    const calculateRollDiceScore = () => {
        console.log("processing dice roll");
        let turnStatus = '';
        let points = 0;
        let numbers = [];
        let pointsText = "";
        numbers[0] = [];
        numbers[1] = [];
        numbers[2] = [];
        numbers[3] = [];
        numbers[4] = [];
        numbers[5] = [];
        numbers[6] = [];

        // loop through and count the times each number appears on the dice rolled
        for (let i=0; i <= currentTurnDice.length-1; i++)
        {
            if (currentTurnDice[i] === 1) {
                numbers[1].push(1);
            }
            if (currentTurnDice[i] === 2) {
                numbers[2].push(1);
            }
            if (currentTurnDice[i] === 3) {
                numbers[3].push(1);
            }
            if (currentTurnDice[i] === 4) {
                numbers[4].push(1);
            }
            if (currentTurnDice[i] === 5) {
                numbers[5].push(1);
            }
            if (currentTurnDice[i] === 6) {
                numbers[6].push(1);
            }
        }

        let diceRemaining = [];
        let triplets = 0;
        let doubles = 0;
        let quadruples = 0;
        numbers.forEach(totals => {
            if (totals.length === 4) {
                quadruples++;
            }
            if (totals.length === 3) {
                triplets++;
            }
            if (totals.length === 2) {
                doubles++;
            }
        });

        // check for 3 pair
        if (triplets === 2) {
            points = 2500;
            pointsText = "2 - Triplets";
        } else if (doubles === 3 || (doubles === 1 && quadruples === 1)) { // check for 2 triplets
            points = 1500;
            pointsText = "3 - Pairs";
        } else if (
            numbers[1].length === 1 &&
            numbers[2].length === 1 &&
            numbers[3].length === 1 &&
            numbers[4].length === 1 &&
            numbers[5].length === 1 &&
            numbers[6].length === 1
        ) { // check for a straight 1-6
            points = 1500;
            pointsText = "straight";
        } else
            // process three, four, five, size of a kind
        if (numbers[1].length === 3) { // PROCESS 3 of a kind on ones
            points = 1000;
            pointsText = "three of a kind on 1s";
        } else if (numbers[1].length === 4) { // PROCESS 4 of a kind on ones
            points = 2000;
            pointsText = "four of a kind on 1s";
        } else if (numbers[1].length === 5) { // PROCESS 5 of a kind on ones
            points = 4000;
            pointsText = "five of a kind on 1s";
        } else if (numbers[1].length === 6) { // PROCESS 6 of a kind on ones
            points = 8000;
            pointsText = "six of a kind on 1s";
        } else  if (numbers[2].length === 3) { // PROCESS 3 of a kind on twos
            points = 200;
            pointsText = "three of a kind on 2s";
        } else  if (numbers[3].length === 3) { // PROCESS 3 of a kind on threes
            points = 300;
            pointsText = "three of a kind on 3s";
        } else  if (numbers[4].length === 3) { // PROCESS 3 of a kind on fours
            points = 400;
            pointsText = "three of a kind on 4s";
        } else  if (numbers[5].length === 3) { // PROCESS 3 of a kind on fives
            points = 500;
            pointsText = "three of a kind on 5s";
        } else  if (numbers[6].length === 3) { // PROCESS 3 of a kind on sixes
            points = 600;
            pointsText = "three of a kind on 6s";
        } else  if (
            numbers[1].length === 4 ||
            numbers[2].length === 4 ||
            numbers[3].length === 4 ||
            numbers[4].length === 4 ||
            numbers[5].length === 4 ||
            numbers[6].length === 4
        ) { // PROCESS 4 of a kind
            points = 1000;
            pointsText = "four of a kind";
        } else  if (
            numbers[1].length === 5 ||
            numbers[2].length === 5 ||
            numbers[3].length === 5 ||
            numbers[4].length === 5 ||
            numbers[5].length === 5 ||
            numbers[6].length === 5
        ) { // PROCESS 5 of a kind
            points = 2000;
            pointsText = "five of a kind";


        } else  if (
            numbers[1].length === 6 ||
            numbers[2].length === 6 ||
            numbers[3].length === 6 ||
            numbers[4].length === 6 ||
            numbers[5].length === 6 ||
            numbers[6].length === 6
        ) { // PROCESS 6 of a kind
            points = 3000;
            pointsText = "six of a kind";
        }

        //if no points scored yet check dice for ones and fives
        if (points === 0) {
            for (let i=0; i <= currentTurnDice.length; i++)
            {
                if (currentTurnDice[i] === 1) {
                    points += 100;
                    pointsText += "1s = 100 ea, ";
                }
                if (currentTurnDice[i] === 5) {
                    points += 50;
                    pointsText += "5s = 50 ea, ";
                }
            }
        }

        if (points === 0) {
            pointsText = "FARKLED!!!!!!!";
        }

        let tmpTurns = turns;
        let currentTurn = {
            dice: diceNum,
            player: {
                name: userName,
                image: userImage
            },
            score: points,
            description: pointsText
        }

        tmpTurns.push(currentTurn);
        const reversedTurns = tmpTurns.map(function iterateItems(item) {
            return item; // or any logic you want to perform
        }).reverse();

        setDisplayTurns(reversedTurns);
        setTurns(tmpTurns);
        setCurrentTurn(tmpTurns.length);
        setCurrentTurn(tmpTurns.length);

        // SET TURN TEXT
        setGameText({
            points: points,
            pointsText: pointsText
        });
    }

    const rollDoneCallback = () => {
        setCurrentTurnDice(diceNum);
        console.log("rollDoneCallback", userName, userImage);
    }

    function passTurnHandler() {
        // this will keep score if applicable

        // change team to the next team
    }

    /*
    useEffect( () => {
        console.log("heldDice", heldDice);
    }, [heldDice]);
     */

    const holdDice = (diceNumber) => {
        //setHeldDice
        let tmpDice = heldDice;
        tmpDice[diceNumber] = !tmpDice[diceNumber];
        setHeldDice(tmpDice);
        forceUpdate();

        console.log("Hold Dice", diceNumber, heldDice)
    }

    return (<div className="container-fluid">
        <div className="container py-4">
            <div className="row">
                <div className="col-12 py-4">
                    <Link className="btn btn-secondary mb-4" aria-current="page" to="/farkle">Back To Farkle Lobby</Link>
                    {!loading && <>
                        <h1>{sessionName}</h1>
                        <small>Current Players: {totalPlayers} | Game Admin: {gameAdmin} | {gameStatus === 'pending' ? <>pending</> : <>in progress</>}</small>
                    </>}
                </div>
                <div className="col-12 text-center">
                    <div className="container rounded bg-warning">
                        <div className="row">
                            <div className="col-2">
                                <br />
                                <button className="btn btn-success btn-lg" onClick={(diceResults) => rollTheDice(1, diceResults)}>ROLL</button><br/><br />
                                <button className="btn btn-primary btn-lg" onClick={passTurnHandler}>PASS</button><br/>
                            </div>

                            <div className="col-10 m-0 px-0 py-4">
                                <ReactDice
                                    rollDone={rollDoneCallback}
                                    numDice={numDice}
                                    outline={outline}
                                    outlineColor={outlineColor}
                                    margin={5}
                                    faceColor={faceColor}
                                    dotColor={dotColor}
                                    dieSize={dieSize}
                                    rollTime={rollSeconds}
                                    ref={diceRoller}
                                    disableIndividual={true}
                                /><br />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <h1>Dice Roll Test</h1>
                    <br /><br />

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {displayTurns && typeof(displayTurns[0]) !== 'undefined' && <>

                                    <h2>Current Turn</h2>
                                    Potential Points : {gameText.points}<br />
                                    Description: {gameText.pointsText}<br />

                                    {displayTurns && typeof(displayTurns[0]) !== 'undefined' && <div className="p-2 rounded m-1">
                                        <img src={`/assets/images/dice/${displayTurns[0].dice[0]}.png`} className={`turnDice ${heldDice[0] && 'diceHeld'}`} onClick={() => holdDice(0)} alt={displayTurns[0].dice[0]} /> &nbsp;
                                        <img src={`/assets/images/dice/${displayTurns[0].dice[1]}.png`} className={`turnDice ${heldDice[1] && 'diceHeld'}`} onClick={() => holdDice(1)} alt={displayTurns[0].dice[1]} /> &nbsp;
                                        <img src={`/assets/images/dice/${displayTurns[0].dice[2]}.png`} className={`turnDice ${heldDice[2] && 'diceHeld'}`} onClick={() => holdDice(2)} alt={displayTurns[0].dice[2]} /> &nbsp;
                                        <img src={`/assets/images/dice/${displayTurns[0].dice[3]}.png`} className={`turnDice ${heldDice[3] && 'diceHeld'}`} onClick={() => holdDice(3)} alt={displayTurns[0].dice[3]} /> &nbsp;
                                        <img src={`/assets/images/dice/${displayTurns[0].dice[4]}.png`} className={`turnDice ${heldDice[4] && 'diceHeld'}`} onClick={() => holdDice(4)} alt={displayTurns[0].dice[4]} /> &nbsp;
                                        <img src={`/assets/images/dice/${displayTurns[0].dice[5]}.png`} className={`turnDice ${heldDice[5] && 'diceHeld'}`} onClick={() => holdDice(5)} alt={displayTurns[0].dice[5]} /> <br />
                                        <span>Choose dice to hold</span>
                                    </div>}

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 pastTurns scrollable">
                                                {displayTurns && typeof(displayTurns[0]) !== 'undefined' && displayTurns.map( (turn, index) => {
                                                    if (index !== 0) {
                                                        return (<div key={index} className={index === 0 ? "p-2 rounded m-1" : "prevTurns p-2 rounded m-1"}>
                                                            <div className="container">
                                                                <div className="row" style={{alignItems: 'center'}}>
                                                                    <div className="col-3">
                                                                        <img src={turn.player.image} alt={turn.player.name} className="profileImageNavbar" width="60" /><br />
                                                                        {turn.player.name}
                                                                    </div>
                                                                    <div className="col">
                                                                        <img src={`/assets/images/dice/${turn.dice[0]}.png`} className={index === 0 ? "turnDice" : "prevTurnDice"} alt={turn.dice[0]} /> &nbsp;
                                                                        <img src={`/assets/images/dice/${turn.dice[1]}.png`} className={index === 0 ? "turnDice" : "prevTurnDice"} alt={turn.dice[1]} /> &nbsp;
                                                                        <img src={`/assets/images/dice/${turn.dice[2]}.png`} className={index === 0 ? "turnDice" : "prevTurnDice"} alt={turn.dice[2]} /> &nbsp;
                                                                        <img src={`/assets/images/dice/${turn.dice[3]}.png`} className={index === 0 ? "turnDice" : "prevTurnDice"} alt={turn.dice[3]} /> &nbsp;
                                                                        <img src={`/assets/images/dice/${turn.dice[4]}.png`} className={index === 0 ? "turnDice" : "prevTurnDice"} alt={turn.dice[4]} /> &nbsp;
                                                                        <img src={`/assets/images/dice/${turn.dice[5]}.png`} className={index === 0 ? "turnDice" : "prevTurnDice"} alt={turn.dice[5]} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span><b>Turn Score:</b> {turn.score} | {turn.description} <br /><b>Cumulative Score:</b> </span>
                                                        </div>);
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                </>}




                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-6">
                    <ChatLobby
                        chatName="Farkle Chat"
                        lobbyName="farkle"
                        socket={socket}
                        userID={userID}
                        signedIn={signedIn}
                        userName={userName}
                        userImage={userImage}
                        lobbyUsers={lobbyUsers}
                        lobby={gameSession}
                    />
                </div>
            </div>
        </div>
    </div>);
}

export default FarkleGame;