import {Link} from "react-router-dom";

import Chat from "../components/Chat";
import PlayersInLobby from "../components/PlayersInLobby";
import GamesAvailable from "../components/GamesAvailable";
import {useEffect} from "react";

const Lobby = ({ socket, userName, userImage, userID, userEmail, lobbyUsers, signedIn }) => {

    useEffect( () => {
        if (socket && userName !== '' && userID !== '' && signedIn) {
            console.log("Changing lobbies : lobby", userName, signedIn, userID);
            socket.emit('changeLobby', {lobbyName: 'lobby', userID});
        }
    }, [socket, userID, signedIn, userName]);

    return (<div className="container-fluid">
        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-4 py-4">
                    <GamesAvailable userName={userName} lobbyUsers={lobbyUsers} />
                </div>
                <div className="col-12 col-sm-4 py-4">
                    <Chat socket={socket} lobbyName="lobby" signedIn={signedIn} userID={userID} userName={userName} userEmail={userEmail} userImage={userImage} />
                </div>
                <div className="col-12 col-sm-4 py-4">
                    <PlayersInLobby lobbyUsers={lobbyUsers} lobby={"lobby"} />

                    <h2 className="pt-4">Players in Game</h2>
                    <div className="card">
                        <div className="card-body">
                            player 1 - Battleship <a href="/">chat</a> | <a href="/">join</a><br/>
                            player 2 - Battleship <a href="/">chat</a> | <a href="/">join</a><br/>
                            player 3 - Battleship <a href="/">chat</a> | <a href="/">join</a><br/>
                            player 4 - Battleship <a href="/">chat</a> | <a href="/">join</a><br/>
                        </div>
                    </div>
                    <h2 className="pt-4">Coming Soon</h2>
                    <div className="card">
                        <div className="card-body">
                            <ul>
                                <li>Yahtzee (2-5 players)</li>
                                <li>Battleship (2 players)</li>
                                <li>Checkers (2 players)</li>
                                <li>Scrabble (2-4 players)</li>
                                <li>Chess (2 players)</li>
                                <li>Hearts (2-4 players)</li>
                                <li>Spades (2-4 players)</li>
                            </ul>
                            These would all be neat right? Submit a suggestion.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default Lobby;