import {Link} from "react-router-dom";
import {GoogleLogin, GoogleLogout} from "react-google-login";
import React from "react";
const Navbar = ({ onSignIn, handleLogout, userName, userImage }) => {
    const handleLoginSuccess = async googleData => {
        onSignIn(googleData);
    }

    const handleLoginFailure = async googleData => {
        console.log("handleLoginFailure", googleData);
    }

    return (<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
            <Link to={'/'}><img src="/assets/images/bb-logo-silversand.svg" className="navbar-brand" width="200" alt="logo" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse text-light" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0" />
                {userName !== '' && <>
                    {userName}
                    {userImage !== '' && <> &nbsp; <img src={userImage} alt={userName} className="profileImageNavbar" width="40" /> &nbsp; &nbsp; &nbsp; </>}
                    <GoogleLogout
                        clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                        buttonText="Logout"
                        render={renderProps => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-sm btn-danger"><i className="fas fa-sign-out-alt" /></button>
                        )}
                        onLogoutSuccess={handleLogout}
                    >
                    </GoogleLogout>
                </>}
                {userName === '' && <>
                    <GoogleLogin
                        clientId="109629232556-3k3bnd23nevfob1hu2fi28sfo08e2hqf.apps.googleusercontent.com"
                        buttonText="Login"
                        render={renderProps => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-sm btn-success">Login <i className="fas fa-sign-in-alt" /></button>
                        )}
                        onSuccess={handleLoginSuccess}
                        onFailure={handleLoginFailure}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                    />
                </>}
            </div>
        </div>
    </nav>);
}
export default Navbar;