import {Link} from "react-router-dom";

const GamesAvailable = ({ userName, lobbyUsers }) => {
    return (<>
        <h2>Games Available</h2>
        <div className="container">
            <div className="row row-cols-1 row-cols-md-2 g-4">
                <div className="col">
                    <div className="card">
                        <Link to={"/games/farkle"}><img src="/assets/images/farkle.png" alt="Farkle" className="card-img-top" /></Link>
                        <div className="card-footer text-center">
                            (2-10 players)<br />
                            {userName && <>
                                <Link to="/farkle" className="btn btn-success btn-sm">PLAY GAME</Link><br/>
                                <span className="text-secondary small">
                                    Current players {lobbyUsers.filter( player => player.lobby === 'farkle').length}
                                </span>
                            </>}
                            {userName === '' && <>Log in to play</>}
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <Link to={"/games/yahtzee"}><img src="/assets/images/yahtzee.png" alt="Yahtzee" className="card-img-top" /></Link>
                        <div className="card-footer text-center">
                            (2-5 players)<br />
                            {userName && <>
                                Coming Soon<br/>
                                <span className="text-secondary small">
                                    Current players {lobbyUsers.filter( player => player.lobby === 'yahtzee').length}
                                </span>
                            </>}
                            {userName === '' && <>Log in to play</>}
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <Link to={"/games/battleship"}><img src="/assets/images/battleship.jpg" alt="Battleship" className="card-img-top" /></Link>
                        <div className="card-footer text-center">
                            (2 players)<br />
                            {userName && <>
                                Coming Soon<br/>
                                <span className="text-secondary small">
                                    Current players {lobbyUsers.filter( player => player.lobby === 'farkle').length}
                                </span>
                            </>}
                            {userName === '' && <>Log in to play</>}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>);
}
export default GamesAvailable;