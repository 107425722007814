import React from "react";

const PlayersInLobby = ({ lobbyUsers, lobby }) => {
    if (lobbyUsers.length > 0) {
        return (<>
            <h2>Players in Lobby</h2>
            <div className="card">
                <div className="card-body">
                    {lobbyUsers && lobbyUsers
                        .sort((a, b) => a.name - b.name)
                        .map( (user, index) => {
                        if (user.lobby === lobby) {
                            return (<span key={index}>
                                {user.image && <span className="user">
                                    <img src={user.image} alt={user.name} className="lobbyListImage" />
                                </span>}
                                    {user.name}<br/>
                            </span>);
                        }
                        return null;
                    })}
                </div>
            </div>
        </>);
    }
    return (null);
}

export default PlayersInLobby;