const Footer = () => {
    return (<footer className="bg-dark text-light">
        <div className="container py-2">
            <div className="row">
                <div className="col-12">
                    &copy; 2022 Ian LeBlanc - <a href="mailto:ian.leblanc@bombbomb.com">ian.leblanc@bombbomb.com</a>
                </div>
            </div>
        </div>
    </footer>);
}
export default Footer;